$grayText: #949494;
$darkGrayText: #6b6b6b;
$lightGrayBackground: #e8e8e8;
$grayButton: #949494;
$main: rgba(0,37,82,1);
$white: white;

.downloadMedia {
  height: 100%;
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }

  @media screen and (max-width: 780px) {
    justify-content: flex-start;
  }

  .disclaimer {
    position:fixed;
    width:100%;
    left:0;
    bottom:0;
    }
.disclaimer p {
      text-align:center;
      color: $grayText;
      letter-spacing: 1px;
      padding: 3%;
      }

  .downloadMediaContainer {
    background-color: $white;
    // border-radius: 6px;
    // overflow: hidden;
    width: 100%;
    height: 100%;
    // max-width: 1000px;

    @media screen and (max-width: 780px) {
      width: 100%;
      height: 100%;
      margin-top: 0;
      border-radius: 0;
    }

    .downloadMediaHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      background-color: $main;
      position: absolute;
      width: calc(100% - 3rem);

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      // width: calc(80% - 2rem);
      // max-width: calc(1000px - 2rem);
      // border-top-left-radius: 6px;
      // border-top-right-radius: 6px;

      // @media screen and (max-width: 780px) {
      //   border-radius: 0;
      //   width: calc(100% - 2rem);
      // }

      // @media screen and (max-width: 615px) {
      //   flex-direction: column;
      //   align-items: flex-start;

      //   a {
      //     margin-top: 1rem;
      //   }
      // }

      .logoAndName {
        display: flex;
        align-items: center;

        img {
          height: 50px;

          @media screen and (max-width: 800px) {
            height: 40px;
          }
          @media screen and (max-width: 375px) {
            height: 30px;
          }
        }

        div:last-of-type {
          color: $grayText;
          text-transform: uppercase;
          font-size: 26px;
          letter-spacing: 1px;
        }

        @media screen and (max-width: 435px) {
          flex-direction: column;
          align-items: flex-start;

          div:first-of-type {
            border-right: 0px;
          }
        }
      }

      a {
        cursor: pointer;
        // background-color: $grayButton;
        color: $white;
        text-transform: uppercase;
        // text-decoration: none;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 1px;
        font-family: 'Times New Roman', Times, serif;

        @media screen and (max-width: 600px) {
          display: none;
        }

        &:visited, &:hover {
          color: $white;
        }
      }
    }

    .downloadMediaContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      background-color: $white;
      padding-top: 12rem;
      height: 100%;
      overflow-y: scroll;

      // @media screen and (max-width: 780px) {
      //   height: 100%;
      //   padding-top: 0rem;
      // }

      @media screen and (max-width: 615px) {
        padding-top: 8rem;
      }

      @media screen and (max-width: 434px) {
        padding-top: 8rem;
      }

      .download {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin: 0;
          padding: 0;
        }
      }

      .downloadReady {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          height: 100px;
        }

        p {
          padding: 1.5rem 0;
        }

        a {
          // background-color: $main;
          color: white;
          text-transform: uppercase;
          text-decoration: none;
          padding: 1rem 2rem;
          border-radius: 16px;
          font-size: 22px;
          letter-spacing: 1px;

          img {
            height: 50px;
          }
        }
      }

      p {
        color: $darkGrayText;
      }
    }
  }
}